html, body {
    margin : 0;
    padding : 0;
}

body {
    background : #ffffff url("../images/css/fond_degrade.png") repeat-x top left;
    min-height : 1300px;
    margin : 0;
    font-family : Arial;
    display : block;
}

.background {
    background : url("../images/css/bandeau-haut.png") no-repeat top;
    width : 100%;
    height : 100%;
    text-align : left;
    margin : 0 auto;
    position :  absolute;	
    z-index : 0;
    top : 0;
    display : block;
}

.container {
    width : 1013px;
    height : auto;
    margin : 210px auto;
    position : relative;
    display : block;
}

.texte{
    font-size : 13px;
    font-weight : bold;
    font-family : Arial;
    display : block;
}
.red {
    color: red
}

/***********CORRESPOND A L'EMPLACEMENT DU LOGO PERSONALISE EN FONCTION DE LA PAGE AFFICHE EN HAUT DE PAGE*************/

.logo {
    position : absolute;
    margin : -100px 0 0 750px;
    display : block;
}
.logo_cros_cdos{
    margin : -135px 0 0 770px;
    display : block;
    position : absolute;
}

/***********CORRESPOND A LA BARRE BLEU AFFICHANT LE LIEN ACCUEIL ET LE TEXTE PERSONALISE EN HAUT DE PAGE*************/

.header {
    background : url("../images/css/barre_bleue_h.png") no-repeat top left;
    overflow : hidden;
    width : 1013px;
    height : 70px;
    margin : 0;
}

.header_accueil {
    margin : 12px 0 0 35px;
    float : left;
}

.header_texte {
    margin : 5px 0 0 50px;
    float : left;
    font-size : 32px;
    font-weight : bold;
    font-family : Myriad Pro, Arial;
    color : White;
}

#contenu{
    overflow: auto;
}

/***********CORRESPOND A L'AFFICHAGE DU MENU DE GAUCHE*************/  

.menu_gauche{
    width : 220px;
    height : 100%;
    float : left;
    margin : 3px 0 15px 5px;
    padding : 15px 0 0 0;
    min-height : 834px;
    background : #f2efe0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;
}
.menu_gauche_index{
    width : 220px;
    height : 100%;
    float : left;
    margin : 3px 0 15px 5px;
    padding : 15px 0 0 0;
    min-height : 800px;
    background : #f2efe0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;
}

/***********CORRESPOND A L'AFFICHAGE DU MENU DE GAUCHE*************/  

.rech_com_titre{
    background : #686765;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow: auto;
    margin : 0 8px 15px 8px;
}

.rech_com_titre p{
    color : #58c8ff;
    text-align : right;
    font-size : 16px;
    font-family : Arial;
    font-weight : bold;
    padding : 0 10px 0 0;
    margin : 3px 0px 0px;
}

.rech_com_menu select{
    margin : 0 0 15px 15px;
    width : 200px;
}

.icon_tooltip
{
    float:left; 
    margin : 3px 0 0 10px; 
    border:0px;
}

/***********CORRESPOND A L'AFFICHAGE DE L'IMAGE DE CONFIRMATION DE RECHERCHE*************/ 

.nav_rech_bouton {
    margin : 0 0 0 10px;
    width : 300px;
    height : 93px;
    background : url("../images/css/bouton_recherche2.png") no-repeat;
    z-index : 24;
    display : block;

}
.nav_rech_bouton_bis {
    margin : 10px 0px -100px 450px;
    width : 300px;
    height : 93px;
    background : url("../images/css/bouton_recherche.png") no-repeat;
    z-index : 24;
    display : block;
}

.nav_rech_bouton input{
    margin : 4px 0 0 2px;
    font-size : 15px;
    font-weight : bold;
    color : #ffffff;
    background-color : transparent;
    border : 0;
}


.nav_rech_bouton input:hover{
    color : #555555;
}
.nav_rech_bouton_bis input{
    margin : 4px 0 0 2px;
    font-size : 20px;
    font-weight : bold;
    color : #ffffff;
    background-color : transparent;
    border : 0;
    align-content: center;
}


.nav_rech_bouton_bis input:hover{
    color : #555555;
}

/***********CORRESPOND A L'AFFICHAGE DU TEXTE DANS LE CADRE BLEU*************/ 

.affichage_texte{
    margin : 0 8px 15px 8px;
    border: 1px solid #58c8ff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow : hidden;
    padding : 10px 10px 10px 10px;
    text-align : center;
    font-weight : bold;
    font-family: Arial;
    color : #28ACFF;
    font-size : 12px;
}
/***********CORRESPOND A L'AFFICHAGE DES 2 ONGLETS*************/ 

.retour_cdos{
    background: url("../images/Bouton-beige-h.png") no-repeat scroll center top transparent;
    width: 210px;
    height: 75px;
    position: absolute;
    margin: -227px 0px 0px 365px;
    padding-top: 15px;
    text-align: center;
    font-size: 13px;
    white-space: normal;
}
.retour_cdos a {
    margin: 0px 30px;
    color: #666;
    font-weight: bold;
    text-decoration: none;
    display: block;
}
.retour_cdos a:hover{
    color : #28ACFF;
    text-decoration: none;
    display : block;
}

.connexion_perso {      
    background:url("../images/Bouton-beige-h.png") no-repeat scroll center top transparent ;
    width: 196px;
    height : 75px;
    position : absolute;
    margin : -227px 0 0 210px;
    padding-top : 20px;
    text-align: center;
    font-size : 13px;
}

.connexion_perso a{
    margin: 0px 30px;
    color: #666;
    font-weight: bold;
    text-decoration: none;
    display: block;
}

.connexion_perso a:hover{
    color : #28ACFF;
    text-decoration: none;
    display : block;
}

.creer-asso {      
        background: url("../images/Bouton-beige-h.png") no-repeat scroll center top transparent;
        width: 200px;
        height: 75px;
        position: absolute;
        margin: -227px 0px 0px 532px;
        padding-top: 15px;
        text-align: center;
        font-size: 13px;
        white-space: normal;
}
    
.creer-asso a{
        color : #666666;
        font-weight : bold;
        text-decoration: none;
        display : block;
        margin : 0 30px 0 30px;
}
    
.creer-asso a:hover{
        color : #28ACFF;
        text-decoration: none;
        display : block;
}

#identification{
    background:url(../images/bloc_identification.png) no-repeat top ;
    background-size: cover;
    position : absolute;
    color : #666666;
    font-weight:bold;
    font-size : 12px;
    margin : -160px 0 0 200px;
    z-index : 30;
    display: none;
    width: 412px;
    height : 127px;
    border : 0px;
}

.identification table{
    padding : 25px 0 0 40px;
}

.identification a{
    color : #666666;
    font-weight : bold;
    text-decoration: none;
    display : block;
    margin : 0 0 0 70px;
}

.identification a:hover{
    color : #28ACFF;
    text-decoration: none;
    display : block;
}

/***********CORRESPOND A L'AFFICHAGE DU CADRE DE CONTENU DROITE*************/  

.nav_rech{
    width : 760px;
    float : right;
    height : 100%;
    margin : 3px -20px 15px 0;
    padding : 15px 0 0 0;
    min-height : 800px;
    background : #f2efe0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;

}
.nav_rech_bis{
    width : 760px;
    float : right;
    margin : 3px -20px 15px 0;
    padding : 15px 0 0 0;
    min-height : 834px;
    background : #f2efe0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;

}
.nav_rech_region{
    width : 760px;
    float : right;
    margin : 3px -20px 15px 0;
    padding : 15px 0 0 0;
    min-height : 834px;
    background : #f2efe0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;

}


/***********CORRESPOND A L'AFFICHAGE DU CADRE REGION CHOISIE************/

.res_region_titre{
    background: #686765;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow: auto;
    margin : 0 8px 15px 8px;
    text-align: center;
    vertical-align: center;
    display: inline-block; 

}

.res_region_titre p{
    color : #58c8ff;
    text-align : left;
    vertical-align: center;
    font-size : 16px;
    font-family : Arial;
    font-weight : bold;
    padding : 0 10px 0 0;
    margin : 1px 10px 0px;
}

#res_region_choix {
    background: #686765;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow: auto;
    padding : 0 0 0 0;
    color : white;
    font-size : 13px;
    margin : 0 0 0 20px;
    font-family : Arial;
    font-weight : bold;
}

/***********CORRESPOND A L'AFFICHAGE DE LA CARTE*************/

.carte{
    margin : 50px 50px 0 10px;
}

#carte_region{ 
    margin : 50px 50px 0 10px;

}
#carte_departement{ 
    margin : 0px 50px 0 10px;
}

/***********CORRESPOND A L'AFFICHAGE DU POST-IT*************/

#post_it{
    float : right;
    position : absolute;
    margin : 150px 0 0 500px;
}

/***********CORRESPOND AU FOOTER*************/  

.footer{
    background : #f2efe0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;
    overflow : hidden;
    width : 1000px;
    height : 100px;
    font-size : 13px;
    font-family : Arial;
    margin : 5px 8px 10px 5px;
}

.footer_partenaire{
    float : left;
}

.footer_partenaire_texte{
    margin : 45px 0 0 25px;
    color : #28ACFF;
    float : left;
}

.footer_partenaire_image{
    margin : 15px 0 0 20px;
    float : left;
}

.footer_droite{
    margin : 40px 22px 0 0;
    color : #28ACFF;
}

.footer_droite a{
    text-decoration : none;
    color : #28ACFF;
}

.footer_mentions{
    float : right;
    color : #28ACFF;
    margin : 5px 0 0 8px;
}

.footer_contact{
    float : right;
    color : #28ACFF;
    margin : 5px 0 0 0;
}

/***********CORRESPOND A L'AFFICHAGE DE LA PAGE MENTIONS LEGALES*************/  

.nav_page_entiere{
    background : #f2efe0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;
    overflow : hidden;
    margin : 0 -20px 10px 5px;
    padding : 0 40px 10px 40px;
}

.nav_page_entiere h1{
    color : #666666;
    font-size : 28px;
    text-align : center;
}

.nav_page_entiere h2{
    padding-left : 40px;
    color : #28ACFF;
    font-size : 20px;
}
.nav_page_entiere h3{
    padding-left: 60px;
    font-size: 15px;
    color: #28ACFF;
    font-style: italic;

}

.nav_page_entiere p{
    text-align : justify;
    font-size : 13px;
}

/***********CORRESPOND A L'AFFICHAGE DE LA PAGE DE CONTACT*************/

.contact_mail{
    font-size : 12px;
    margin  : 0 30px 0 30px;
    color : #666666;
}

.contact_mail h1{
    font-weight: bold;
    font-size : 28px;
    text-align : center;
}

.contact_mail h2{
    text-align : left;
    font-size : 14px;
    text-align : center;
    color : #666666;
}

.contact_mail a{
    color : #666666;
    text-decoration : none;
}

.contact_mail a:hover{
    color : #28ACFF;
    text-decoration : none;
}

/***********CORRESPOND A L'AFFICHAGE DU CADRE DE RECHERCHE ASSOCIATION*************/

.nav_page_entiere_recherche{
    background : #f2efe0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;
    overflow : hidden;
    margin : 0 -20px 10px 5px;
    padding : 0 10px 10px 10px;
    font-size: 20px;
}
.nav_page_entiere_recherche p{

    font-size: medium;
    text-align: center;
    color : #666666;
    vertical-align: center;
}


.res_club{
    position:relative;
    margin : 0px 0 0 3px;

}

.res_club_body{
    background : #ffffff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow:hidden;
    padding: 0px 10px 10px 5px;
    margin: 10px 0 0 0;
}

.res_club_body table{
    margin : 0 0 0 5px;
    padding: 0 0 20px 0;
    font-size : 12px;
    border-collapse:collapse;
    font-weight : bold;
}

.signal_titre_H1{
    color: #666666;;
    text-align: center;
    font-weight: bold;
}

.signal_titre_H5{
    color: #28ACFF;
    text-align: center;
    font-size: medium;
    font-weight: bold;
}

.signal_titre_H4{
    color: #666666;
    text-align: center;
    font-size: small;
    font-weight: bold;
}


/***********CORRESPOND A L'AFFICHAGE DU TABLEAU DE RESULTAT DE COMITE DEPARTEMENTALE OU LIGUE*************/  

.res_com_dept_body{
    width : 500px;
    font-size : 12px;
    background : #ffffff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow:hidden;
    padding: 0px 10px 10px 5px;
    margin: 10px auto 0 auto;
}

.res_com_dept_body .title{
    color : #28ACFF;
    text-align: center;
    font-weight: bold;
    font-size : 15px;
    padding : 10px 0 10px 0;
}

.res_comite_dept a{
    color: #444444;
    text-decoration:underline;
}

.res_comite_dept a:hover{
    color: #28ACFF;
    text-decoration:underline;
    font-weight : bold;
}


/***********CORRESPOND A L'AFFICHAGE DU TABLEAU DE RESULTAT DE RECHERCHE ASSOCIATION*************/  

.affich_tab_result table{
    text-align: center;
}

.affich_tab_result th{
    color:#444444;
    font-size : 15px;
    background-color:#8ad2ff;
    font-weight : bold;
}

.tr{
    background-color: #ffffff;
}

.tr2{
    background-color: #F0EDE0;
}

.image_petit{
    height: 30px;
}

a.info{
    position:block;
    z-index:24;
    color:#000;
    text-decoration:none
}

a.info:hover{
    z-index:25;
}

a.info span{
    display: none;
    z-index: 25;
}

a.info:hover span{
    display:block;
    position:relative;
    top:0em; left:12em; width:20em;
    border:1px solid #000;
    background-color:#FFF;
    color: black;
    text-align: justify;
    font-weight: none;
    padding:5px;
    z-index: 25;
}


/***********CORRESPOND A L'AFFICHAGE DU MENU DE GAUCHE DETAIL ASSOCIATION*************/  

.nav_detail_gauche{
    float : left;
    width : 290px;
    margin : 0 0 0 5px;
}

.nav_asso_detail{
    background : #64c7e7;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;
    overflow:hidden;
    margin : 0 0 10px 0;
    padding : 15px 10px 15px 15px;
    font-size : 15px;
    word-wrap : break-word;
    color: #ffffff;
    text-align : left;
    line-height : 15px;
}

.nav_asso_detail a{
    text-decoration : none;
    color : #ffffff;
}
.nav_asso_detail a:hover{
    text-decoration : none;
    color : #666666;
    font-weight : bold;

}
.info_assoc{
    font-size : 1px;
    line-height : 5px;
}

.left {

    float:left;
    width: 33em;
}
.right {

    float:right;
    width: 40em;
    font-size: 12px;
    line-height:1;
    vertical-align: top;
}

.centrer {

    float:center;
    width: 40em;
    font-size: 12px;
    line-height:1;
    vertical-align: top;
} 

.clear {
    clear: both;
}


/***********CORRESPOND A L'AFFICHAGE DU CONTENU DE DETAIL ASSOCIATION*************/  

.nav_detail_body{
    width : 700px;
    float : right;
    height : 100%;
    margin : 3px -20px 15px 0;
    padding : 15px 0 0 0;
    min-height : 880px;
    background : #f2efe0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px -2px #aaa4ae;

}

#global_map{
    padding :0 50px 0 50px;
    color : #666666;
}

#global_map a{
    text-decoration : none;
    color : #666666;
}

#global_map a:hover{
    color : #28ACFF;
}

#global_map h4{
    margin : 0;
    font-size : 18px;
    color : #28ACFF;
}

#global_map .commune_map{
    margin-left : 60px;
}

/***********CORRESPOND A L'AFFICHAGE DU MENU DE GAUCHE*************/  



/*.nav_rech_res_top{
    background:url("../images/bloc_beige_resultats_h.png") no-repeat top left;
    overflow:hidden;	
    height:19px;
}
.nav_rech_res_body{
    background:url("../images/bloc_beige_resultats_m.png") repeat-y top left;
    overflow:hidden;
    padding:0 15px;
    z-index: -1;
}
.nav_rech_res_bottom{
    background:url("../images/bloc_beige_resultats_b.png") no-repeat top left;
    overflow:hidden;	
    height:20px;
    z-index:-1;
}*/


/* images manquantes dans le projet */

/***********CORRESPOND A L'AFFICHAGE DANS LES FORMULAIRES FORMATION, NEWS, MJSS, *************/  

.tableau
{
 border:1px  #DBD9D9;
 border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
 box-shadow: 0 0 0 1px #DBD9D9 ;
    display: block;
}


/***********CORRESPOND Aux Glyphicons *************/  
@font-face {
	font-family: 'icomoon';
	src:url('fonts/icomoon.eot?-jmk3ls');
	src:url('fonts/icomoon.eot?#iefix-jmk3ls') format('embedded-opentype'),
		url('fonts/icomoon.woff?-jmk3ls') format('woff'),
		url('fonts/icomoon.ttf?-jmk3ls') format('truetype'),
		url('fonts/icomoon.svg?-jmk3ls#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
        
}

.icon-cross:after {
	content: "\ea0f";
}
.icon-cross:hover:after {
color : #666666;
}
.icon-cross:active {
box-shadow: inset 0px 2px 2px 2px #C5C5C5 ;
}

.icon-home:after {
	content: "\e900";
}
.icon-home:hover:after {
color : #666666;
}

.icon-user:after {
	content: "\e971";
}
.icon-user:hover:after {
color : #666666;
}

.icon-lock:after {
	content: "\e98f";
}
.icon-lock:hover:after {
color : #666666;
}

.icon-stats-dots:after {
	content: "\e99b";
}
.icon-stats-dots:hover:after {
color : #666666;
}

.icon-switch:after {
	content: "\e9b6";
}
.icon-switch:hover:after {
color : #666666;
}

.icon-plus:after {
	content: "\ea0a";
}
.icon-plus:hover:after {
color : #666666;
}
.icon-plus:active {
box-shadow: inset 0px 2px 2px 2px #C5C5C5 ;
}
/* #logo_cdos {
             position : absolute;
             margin : -125px 0 0 785px;
     }
     
     #logo_cdos_2 {
             position : absolute;
             margin : -125px 0 0 785px;
     }

     #logo_cdos_3 {
             position : absolute;
             margin : -105px 0 0 765px;
     }	
     #retour_cdos{
             background:url(images/annuaire/Bouton-beige-h.png) no-repeat top ;
             width: 196px;
             height : 75px;
             position : absolute;
             margin : -227px 0 0 365px;
             padding-top : 15px;
             text-align: center;
             font-size : 12px;
     }
     
     #retour_cdos a{
             margin : 0 30px 0 30px;
             color : #666666;
             font-weight : bold;
             text-decoration: none;
             display : block;
     }
     
     #retour_cdos a:hover{
             color : #28ACFF;
             text-decoration: none;
             display : block;
     }
     
     #connexion_perso {
             background:url(images/annuaire/Bouton-beige-h.png) no-repeat top ;
             width: 196px;
             height : 75px;
             position : absolute;
             margin : -227px 0 0 210px;
             padding-top : 20px;
             text-align: center;
             font-size : 12px;
     }
     
     #connexion_perso a{
             color : #666666;
             font-weight : bold;
             text-decoration: none;
             display : block;
             margin : 0 30px 0 30px;
     }
     
     #connexion_perso a:hover{
             color : #28ACFF;
             text-decoration: none;
             display : block;
     }
     
     #identification{
             background:url(images/annuaire/bloc_identification.png) no-repeat top ;
             background-size: cover;
             position : absolute;
             color : #666666;
             font-weight:bold;
             font-size : 12px;
             margin : -160px 0 0 200px;
             z-index : 30;
             display: none;
             width: 412px;
             height : 127px;
             border : 0px;
     }
     
     #identification table{
             padding : 25px 0 0 40px;
     }
     
     #identification a{
             color : #666666;
             font-weight : bold;
             text-decoration: none;
             display : block;
             margin : 0 0 0 70px;
     }
     
     #identification a:hover{
             color : #28ACFF;
             text-decoration: none;
             display : block;
     }
     
     #container {
             margin:210px auto;
             position:relative;
             z-index:1;
             width:1010px;
     }
     
     #header_accueil{
             margin: 12px 0 0 35px;
             float:left;
     }
     
     #menu_gauche{
             width: 240px;
             float:left;
     }
     
     #menu_gauche_top{
             background:url(images/annuaire/bloc_beige_gauche_h.png) no-repeat top left;
             overflow:hidden;
             height:20px;
     }
     
     #menu_gauche_body{
             background:url(images/annuaire/bloc_beige_gauche_m.png) repeat-y top left;
             min-height: 740px;
     }
     
     #menu_gauche_bottom{
             background:url(images/annuaire/bloc_beige_gauche_b.png) no-repeat top left;
             overflow:hidden;
             height:20px;
     }
     
     #rech_com_titre{
             background:url(images/annuaire/cadre_titre_commune.png) no-repeat top center;
             overflow:hidden;
             height:60px;
             margin : 0 5px 15px 0;
     }
     
     #rech_com_titre_CA{
             background:url(images/annuaire/cadre_titre_club_CA.png) no-repeat top center;
             overflow:hidden;
             height:60px;
             margin : 0 5px 15px 0;
     }
     
     
     #rech_com_titre_region{
             background:url(images/annuaire/cadre_titre_region.png) no-repeat top center;
             overflow:hidden;
             height:60px;
             margin : 0 5px 15px 0;
     }
     
     
     
     
     #rech_com_titre_france{
             background:url(images/annuaire/cadre_titre_depart.png) no-repeat top center;
             overflow:hidden;
             height:60px;
             margin : 0 5px 15px 0;
     }
     
     #rech_com_menu select{
             margin : 0 0 15px 15px;
             width : 200px;
     }
     
     #rech_fede_titre{
             background:url(images/annuaire/cadre_titre_federation.png) no-repeat top center;
             overflow:hidden;
             height:53px;
             margin : 0 5px 0 0;
     }
     
     #rech_fede_titre_info{
             margin : 8px 0 0 20px;
             font-size : 10px;
     }
     
     #rech_fede_titre_info a.info{
             position:absolute;
             z-index:24;
             color:#000;
             text-decoration:none
     }
      
     #rech_fede_titre_info a.info:hover{
             z-index:25;
     }
      
     #rech_fede_titre_info a.info span{
             display: none
     }
      
     #rech_fede_titre_info a.info:hover span{
             display:block;
             position:absolute;
             top: 2em; left:2em; width:41em;
             border:1px solid #000;
             background-color:#FFF;
             color: black;
             text-align: justify;
             font-weight: none;
             padding:1px;
     }
     
     #rech_fede_menu select{
             margin : 0 0 15px 15px;
             width : 200px;
     }
     
     #rech_label_titre{
             background:url(images/annuaire/cadre_titre_label.png) no-repeat top center;
             overflow:hidden;
             height:53px;
             margin : 0 5px 0 0;
     }
     
     #rech_label_mjss{
     
             margin : 0 0 20px 15px;
     
     }
     #rech_label_mjss_top{
             background:url(images/annuaire/cadre_bleu_h.png) no-repeat top left;
             overflow:hidden;
             height:14px;
     }
     
     #rech_label_mjss_body{
             background:url(images/annuaire/cadre_bleu_m.png) repeat-y top left;
             overflow:hidden;
             padding : 0 18px 0 0;
             text-align:center;
             font-weight: bold;
             color : #28ACFF;
             font-size : 12px;
     }
     
     #rech_label_mjss_bottom{
             background:url(images/annuaire/cadre_bleu_b.png) no-repeat top left;
             overflow:hidden;
             height:14px;
     }
     
     #rech_label_handi{
     
             margin : 0 0 20px 15px;
     
     }
     #rech_label_handi_top{
             background:url(images/annuaire/cadre_bleu_h.png) no-repeat top left;
             overflow:hidden;
             height:14px;
     }
     
     #rech_label_handi_body{
             background:url(images/annuaire/cadre_bleu_m.png) repeat-y top left;
             overflow:hidden;
             padding : 0 25px 0 5px;
             text-align:center;
             font-weight: bold;
             color : #28ACFF;
             font-size : 12px;
     }
     
     #rech_label_handi_bottom{
             background:url(images/annuaire/cadre_bleu_b.png) no-repeat top left;
             overflow:hidden;
             height:14px;
     }
     
     
     #rech_label_sa{
             margin : 0 0 0 15px;
     }
     
     #rech_label_sa_top{
             background:url(images/annuaire/cadre_bleu_h.png) no-repeat top left;
             overflow:hidden;
             height:14px;
     }
     
     #rech_label_sa_body{
             background:url(images/annuaire/cadre_bleu_m.png) repeat-y top left;
             overflow:hidden;
             padding : 0 25px 0 5px;
             text-align:center;
             font-weight: bold;
             color : #28ACFF;
             font-size : 12px;
     }
     
     #rech_label_sa_bottom{
             background:url(images/annuaire/cadre_bleu_b.png) no-repeat top left;
             overflow:hidden;
             height:14px;
     }
     
     
     
     
     
     
     
     
     

     #res_terri_left{
             background:url(images/annuaire/cadre_titre_territoire_g.png) no-repeat top left;
             overflow:hidden;
             float : left;
             height:53px;
             width:151px; 
     }

     #res_terri_left_CA{
             background:url(images/annuaire/cadre_titre_commune_g.png) no-repeat top left;
             overflow:hidden;
             float : left;
             height:53px;
             width:151px; 
     }
     
     #affiche_depart{
             background:url(images/annuaire/cadre-titre-region.png) no-repeat top left;
             overflow:hidden;
             float : left;
             height:37px;
             width:337px;
     
     }
     
     
     
     #res_terri_body{
             background:url(images/annuaire/cadre_titre_territoire_m.png) repeat-x top left;
             overflow:hidden;
             height: 53px;
             float:left;
     }
     
     #res_terri_right{
             background:url(images/annuaire/cadre_titre_territoire_d.png) no-repeat top left;
             overflow:hidden;	
             height:53px;
     }
     
     
     
     
     
     
     
*/


/*******************************************************************   Carto page principale   ************************************************************************/

/*
#res_comite_dept{
        margin : 0 0 0 0;
}

#tab_asso{
        margin : 0 10px 0 250px;
        width : 240px;
        float : left;
        line-height : 20px;
}

#tab_correspondant{
        margin : 0 250px 0 500px;;
        line-height : 20px;
}

#tab_label{
        margin : 0 250px 0 250px;
}

#comite_dept .title{
        color : #28ACFF;
        text-align: center;
        font-weight: bold;
        font-size : 15px;
        margin : 0 250px 0 250px;
}

#res_comite_dept a{
        color: #444444;
        text-decoration:underline;
}

#res_comite_dept a:hover{
        color: #28ACFF;
        text-decoration:underline;
        font-weight : bold;
}

#res_com_dept_top{
        background:url(images/annuaire/petit_cadre_blanc_h.png) no-repeat top center;
        overflow:hidden;
        height:18px;
}

#res_com_dept_body{
        background:url(images/annuaire/petit_cadre_blanc_m.png) repeat-y top center;
        overflow:hidden;
        padding : 0 12px 0 10px;
}

#res_com_dept_bottom{
        background:url(images/annuaire/petit_cadre_blanc_b.png) no-repeat top center;
        overflow:hidden;
        height:19px;
}

#comite_dept{
        font-size : 12px;
}

#res_club{
        position:relative;
        margin : 0px 0 0 3px;

}

#res_club_top{
        background:url(images/annuaire/grand_cadre_blanc_h.png) no-repeat top left;
        overflow:hidden;
        height:19px;
        z-index: 24;
}

#res_club_body{
        background:url(images/annuaire/grand_cadre_blanc_m.png) repeat-y top left;
        overflow:hidden;
        padding: 0 20px 0 5px;
        z-index: 26;
}

#res_club_bottom{
        background:url(images/annuaire/grand_cadre_blanc_b.png) no-repeat top left;
        overflow:hidden;
        height:19px;
        position:relative;
        z-index: 24;
        
}

#res_club_body table{
        margin : 0 0 0 5px;
        font-size : 12px;
        border-collapse:collapse;
        font-weight : bold;
}

#rech_etendue a{
        text-decoration:none;
        color:#666666;
}

#rech_etendue a:hover{
        text-decoration:none;
        color:#28ACFF;
}

#affich_tab_result table{

        text-align: center;

}

#affich_tab_result th{
        color:#444444;
        font-size : 15px;
        background-color:#8ad2ff;
        font-weight : bold;
}

#td{
        background-color: #ffffff;
}

#td a.info{
        position:block;
        z-index:24;
        color:#000;
        text-decoration:none
}

#td a.info:hover{
        z-index:25;
}
 
#td a.info span{
        display: none;
        z-index: 25;
}
 
#td a.info:hover span{
        display:block;
        position:relative;
        top:0em; left:12em; width:20em;
        border:1px solid #000;
        background-color:#FFF;
        color: black;
        text-align: justify;
        font-weight: none;
        padding:5px;
        z-index: 25;
}

#td2{
        background-color: #F0EDE0;
}

#td2 a.info{
        position:block;
        z-index:24;
        color:#000;
        text-decoration:none
}
 
#td2 a.info:hover{
        z-index:25;
}
 
#td2 a.info span{
        display: none;
        z-index:25;
}
 
#td2 a.info:hover span{
        display:block;
        position:relative;
        top:0em; left:12em; width:20em;
        border:1px solid #000;
        background-color:#FFF;
        color: black;
        text-align: justify;
        font-weight: none;
        padding:5px;
        z-index:25;
}




#nav_detail_gauche{
        float : left;
        width : 290px;
        margin : 0 0 0 5px;
}

#nav_asso_detail_top{
        background:url(images/annuaire/bloc_bleu_details_h.png) no-repeat top left;
        overflow:hidden;
        height:14px;
}

#nav_asso_detail_body{
        background:url(images/annuaire/bloc_bleu_details_m.png) repeat-y top left;
        overflow:hidden;
        padding : 0 10px 5px 15px;
        font-size : 15px;
        word-wrap : break-word;
        color: #ffffff;
        text-align : left;
        line-height : 15px;
}

#nav_asso_detail_body a{
        text-decoration : none;
        color : #ffffff;
}
#nav_asso_detail_body a:hover{
        text-decoration : none;
        color : #666666;
        font-weight : bold;
}

#nav_asso_detail_bottom{
        background:url(images/annuaire/bloc_bleu_details_b.png) no-repeat top left;
        overflow:hidden;
        height:18px;
}

#nav_corres_detail_top{
        background:url(images/annuaire/bloc_bleu_details_h.png) no-repeat top left;
        overflow:hidden;
        height:14px;
}

#nav_corres_detail_body{
        background:url(images/annuaire/bloc_bleu_details_m.png) repeat-y top left;
        overflow:hidden;
        padding : 0 15px 5px 15px;
        font-size : 15px;
        color: #ffffff;
        line-height : 15px;
}

#nav_corres_detail_bottom{
        background:url(images/annuaire/bloc_bleu_details_b.png) no-repeat top left;
        overflow:hidden;
        height:18px;
}
#nav_corres_detail_body a{
        text-decoration : none;
        color : #ffffff;
}
#nav_corres_detail_body a:hover{
        text-decoration : none;
        color : #666666;
        font-weight : bold;
}

#nav_map_detail_top{
        background:url(images/annuaire/bloc_beige_detail_h.png) no-repeat top right;
        overflow:hidden;
        height:27px;
}

#nav_map_detail_body{
        background:url(images/annuaire/bloc_beige_detail_m.png) repeat-y top right;
        overflow:hidden;
        font-size : 15px;
}

#nav_map_detail_bottom{
        background:url(images/annuaire/bloc_beige_detail_b.png) no-repeat top right;
        overflow:hidden;
        height:27px;
}





#signal_erreur{
        text-align: left;
        font-size : 12px;
        margin : 0 30px 0 30px;

}

#signal_erreur h1{
        color : #666666;
        font-weight: bold;
        font-size : 28px;
        text-align : center;
}

#signal_erreur h3{
        color : #28ACFF;
        font-weight: bold;
        font-size : 16px;
        text-align : center;
}

#signal_erreur_tab input, #signal_erreur_tab div, #signal_erreur_tab select
{
        margin-left : 200px;
}

#signal_erreur_tab th
{
        width: 302px;
}


#global_map{
        padding :0 50px 0 50px;
        color : #666666;
}

#global_map a{
        text-decoration : none;
        color : #666666;
}

#global_map a:hover{
        color : #28ACFF;
}

#global_map h4{
        margin : 0;
        font-size : 18px;
        color : #28ACFF;
}

#global_map .commune_map{
        margin-left : 60px;
}

#footer{
        background:url(images/annuaire/Barre_beige_b.png) no-repeat top left;
        overflow:hidden;
        width:1018px;
        height:107px;
        font-size : 13px;
        margin-left : -2px;
}

#footer_partenaire{
        float : left;
}

#footer_partenaire_texte{
        margin : 45px 0 0 25px;
        color : #28ACFF;
        float : left;
}

#footer_droite{
        margin : 40px 22px 0 0;
}

#footer_droite a{
        text-decoration : none;
        color : #28ACFF;
}

#footer_mentions{
        float : right;
        color : #28ACFF;
        margin : 5px 0 0 20px;
}

#footer_contact{
        float : right;
        color : #28ACFF;
        margin : 5px 0 0 0;
}
*/

/* //////////////////////////////////////////////////////////////////////////////////////////  */
/* //////////////////////////////////////////////////////////////////////////////////////////  */

/* l'espace personnelle du site sport 86 commence ici  */

/* //////////////////////////////////////////////////////////////////////////////////////////  */

/* //////////////////////////////////////////////////////////////////////////////////////////  */
/*
#retour_sport86{
        background:url(images/annuaire/Bouton-beige-h.png) no-repeat top ;
        width: 196px;
        height : 75px;
        position : absolute;
        margin : -215px 0 0 195px;
        padding-top : 13px;
        text-align: center;
        font-size : 12px;
}

#retour_sport86 a{
        margin : 0 30px 0 30px;
        color : #666666;
        font-weight : bold;
        text-decoration: none;
        display : block;
}

#retour_sport86 a:hover{
        color : #28ACFF;
        text-decoration: none;
        display : block;
}
*/
/*pour la page de formulaire perso*/

/*
.centrage_titre_bleu  /* On met un peu plus en valeur les titres des fieldset */
/*	
{
                color: #0090F0;
                font-weight: bold;
                text-align : center; 
        }
        
        .cach_verif_botte { margin : 0px 1000px;}

        champ_obligatoire
        { 
        font-size : 12px; 
        margin : 0px 0px 0px 20px;
        }
        
        #tablo_creation_compte{
                margin : 0px 0px 0px 30px ;
        }
        
        #tablo_creation_compte td{
                font-size : 13px;
        }

        #tablo_creation_compte th {
                font-size : 13px;
        }
*/
